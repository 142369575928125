<template app>
   <div>
 logs
    </div>
</template>
<script>
    export default {
        data() {
            return {
           

            }
        },
        computed: {
          
        },
        mounted() {
       
        },
        created() {

        },
        methods: {
         
        }
    }
</script>

<style>

</style>
